import { RouteRecordRaw } from 'vue-router';

const ProjMgmtRoutes: RouteRecordRaw[] = [
  {
    path: '/proj-mgmt',
    component: () => import('@/components/proj-mgmt/MgmtView.vue'),
    meta: { auth: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_ADMIN_LOWER', 'ROLE_INSTITUTION_USER'], group: 'proj-mgmt', menuGroup: 'proj-mgmt' },
    children: [
      { path: '', redirect: '/proj-mgmt/list' },
      { path: 'list', redirect: '/proj-mgmt/list/1' },
      {
        path: 'list/:page',
        component: () => import('@/components/proj-mgmt/ListView.vue'),
        meta: { breadcrumb: [{ label: '项目申报列表' }] },
      },
      { path: 'recommend', redirect: '/proj-mgmt/recommend/1' },
     
      {
        path: 'recommend/:page',
        component: () => import('@/components/proj-mgmt/RecommemdView.vue'),
        meta: { breadcrumb: [{ label: '项目推荐列表' }] },
      },
      {
        path: 'filesManage/:page',
        component: () => import('@/components/proj-mgmt/fileManage/FilesManage.vue'),
        meta: { breadcrumb: [{ label: '项目文件管理' }] },
      },
      {
        path: 'evaluate/:page',
        component: () => import('@/components/proj-mgmt/evaluateView.vue'),
        meta: { breadcrumb: [{ label: '评价阶段推荐' }] },
      },
      // { path: 'evaluate/:page', redirect: '/proj-mgmt/evaluate/1' },
      { path: 'detail', redirect: '/proj-mgmt/list/1' },
      {
        path: 'detail/:id',
        component: () => import('@/components/proj-mgmt/DetailView.vue'),
        meta: { breadcrumb: [{ label: '项目申报详情' }] },
      },
    ],
  },
];

export default ProjMgmtRoutes;
