import http from './http';
import { BASE_URL } from './ENV';
import { getToken } from '@/utils';

export function login(data) {
  return http.post('/authorize/token', data);
}

export function validationMobileCode(param) {
  return http.get(`/authorize/validation/mobileCode`, {params: param});
}

function getActivityCategory() {
  return http.get(`/expert/activity`);
}

function postExpertCommitment(activityId: string) {
  return http.post(`/expert/commitment?activityId=${activityId}`);
}

function getExpertInfo() {
  return http.get(`/expert/profile`);
}

function getExpertProjectList(data: any) {
  return http.get(`/expert/project`, { params: data });
}

function putExpertIcon(avatarId: string) {
  return http.put(`/expert/avatar?avatar=${avatarId}`);
}

function getExpertProjectDetail(id: string) {
  return http.get(`/expert/project/${id}`);
}

function getExpertScore(id: string) {
  return http.get(`/expert/score?id=${id}`);
}

function putExpertScore(data: any) {
  return http.put(`/expert/score`, data);
}

// 申报中心列表
function getActivityList(template: string, page: number, size: number) {
  return http.get(`/activity/me/${template}?page=${page}&size=${size}`);
}
// 我的申报列表
function getAppliedList(template: string, page: number, size: number) {
  return http.post(
    `/activity/item/search/${template}?page=${page}&size=${size}`,
  );
}

function getAddressData() {
  return http.get('/location');
}
// 单位性质
function getOrganizationType(activityID: string) {
  return http.get(`/organization/types/${activityID}`);
}

function getProductType(activityID: string) {
  return http.get(`/product/types/${activityID}`);
}

function getInstitution(activityID: string) {
  return http.get(`/institution/${activityID}`);
}
// 上传文件
function uploadAppenderFile(formData: FormData, config = {}) {
  return http.post(`/file/uploadAppenderFile`, formData, config);
}

function uploadFile(formData: FormData) {
  return http.post('/file/upload', formData);
}

function postForm(data: Object) {
  return http.post('/activity/item', data);
}

function getFormDetail(id: string) {
  return http.get(`/activity/item/${id}`);
}
// 受理通过
function putActivityAccepted(id: number) {
  return http.put(`/activity/item/accepted/${id}`);
}
// 受理不通过
function deleteActivityAccepted(id: number) {
  return http.delete(`/activity/item/accepted/${id}`);
}

// 推荐
function getActivityRecommend(id: number, desc: string) {
  return http.put(`/activity/item/recommendation/${id}`, desc, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}
// 推荐
function getActivityMidRecommend(id: number, desc: string) {
  return http.put(`/activity/item/mid-term-summary/recommendation/${id}`, desc, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}
// 编辑推荐
function getActivityUpdateRecommend(id: number, desc: string) {
  return http.put(`/activity/item/recommendation/reason/${id}`, desc, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

// 编辑推荐
function getActivityUpdateMidRecommend(id: number, desc: string) {
  return http.put(`/activity/item/mid-term-summary/recommendation/reason/${id}`, desc, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}
// 推荐
function getActivityUpdateMidRecommendation(id: number, desc: string) {
  return http.put(`/activity/item/mid-term-summary/recommendation/${id}`, desc, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}
// 取消推荐
function removeActivityRecommend(id: number) {
  return http.delete(`/activity/item/recommendation/${id}`);
}

// 取消推荐
function removeActivityMidRecommend(id: number) {
  return http.delete(`/activity/item/mid-term-summary/recommendation/${id}`);
}



// 重置密码短信发送
function getResetPasswordSms(mobile: string) {
  return http.get(`/sms/password/reset?mobile=${mobile}`);
}

// 注册短信短信验证码发送
function getRegisterSms(mobile: string) {
  return http.get(`/sms/register?mobile=${mobile}`);
}

// 注册
function postRegister(data: Object) {
  return http.post('/authorize/register', data);
}

// 通过手机号重置密码
function postResetPassword(data: Object) {
  return http.post('/authorize/resetPassword', data);
}

// 通过旧密码重置密码
function postResetPasswordFromOldPwd(data: Object) {
  return http.post('/authorize/resetWithPassword', data);
}

function getProfile() {
  return http.get('/user/me');
}

function postProfile(data: Object) {
  return http.post('/user/me', data);
}

function getActivityType() {
  return http.get('/institution/activity');
}

function postActivityLocationList(data: Object, params: Object) {
  return http.post(`/activity/item/institution/search`, data, {
    params: params,
  });
}

function downloadFile(id: string, fileName: string = '材料.pdf') {
  const link = document.createElement('a');
  link.href = `${BASE_URL}/file/download?id=${id}&Authorization=${getToken()}`;
  link.download = fileName;
  link.click();
  link.remove();
  // return new Promise((res, rej) => {
  //   const xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.onload = function ({ target }) {
  //     if ((target as XMLHttpRequest).status === 401) {
  //       return rej(target);
  //     }
  //     const blob = new Blob([xhr.response], {
  //       type: 'application/octet-stream',
  //     });
  //     let link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     const contentDisposition = xhr.getResponseHeader('content-disposition');
  //     if (contentDisposition) {
  //       const descArrays = contentDisposition.split('=');
  //       fileName = decodeURI(descArrays[1]);
  //     }
  //     link.download = fileName;
  //     link.click();
  //     link.remove();
  //     window.URL.revokeObjectURL(link.href);
  //     res(target);
  //   };
  //   // xhr.responseType = "blob";
  //   xhr.open('GET', `${BASE_URL}/file/download?id=${id}`);
  //   xhr.setRequestHeader('Authorization', getToken());
  //   xhr.send();
  // });
}

function getUserIcon(id: number) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function ({ target }) {
      if ((target as XMLHttpRequest).status === 401) {
        return rej(target);
      }
      const blob = xhr.response;
      var url = window.URL.createObjectURL(blob);
      res(url);
    };
    xhr.open('GET', `${BASE_URL}/file/download?id=${id}`);
    xhr.setRequestHeader('Authorization', getToken());
    xhr.send();
  });
}

function getFileExport(activityId: number, key: string = '') {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function ({ target }) {
      if ((target as XMLHttpRequest).status !== 200) {
        return rej(target);
      }

      const blob = new Blob([xhr.response], {
        // type: 'application/octet-stream',
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = '';
      const contentDisposition = xhr.getResponseHeader('content-disposition');
      if (contentDisposition) {
        const descArrays = contentDisposition.split('=');
        fileName = decodeURI(descArrays[1]);
      }
      link.download = fileName;
      link.click();
      res(target);
    };
    xhr.responseType = 'blob';
    xhr.open(
      'GET',
      `${BASE_URL}/activity/item/institution/export/${activityId}?key=${key}`,
    );
    xhr.setRequestHeader('Authorization', getToken());
    xhr.send();
  });
}

function getPdfFileExport(ids: string) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function ({ target }) {
      if ((target as XMLHttpRequest).status !== 200) {
        return rej(target);
      }

      const blob = new Blob([xhr.response], {
        type: 'application/pdf',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let fileName = '';
      const contentDisposition = xhr.getResponseHeader('content-disposition');
      if (contentDisposition) {
        const descArrays = contentDisposition.split('=');
        fileName = decodeURI(descArrays[1]);
      }
      link.download = fileName;
      link.click();
      res(target);
    };
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function() {

    };
    xhr.open(
      'POST',
      `${BASE_URL}/institution/recommend/file/download?Authorization=${getToken()}`,
    );
    // xhr.setRequestHeader('Authorization', getToken());
    xhr.send(ids);
  });
}
function getRecommemdWordExport(key: string = '', activityId: number) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function ({ target }) {
      if ((target as XMLHttpRequest).status !== 200) {
        return rej(target);
      }

      const blob = new Blob([xhr.response], {
        type: 'application/msword',
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      let fileName = '';
      link.href = window.URL.createObjectURL(blob);
      const contentDisposition = xhr.getResponseHeader('content-disposition');
      if (contentDisposition) {
        const descArrays = contentDisposition.split('=');
        fileName = decodeURI(descArrays[1]);
      }
      link.download = fileName;
      link.click();
      res(target);
    };
    xhr.responseType = 'blob';
    xhr.open(
      'GET',
      `${BASE_URL}/activity/item/institution/export/${activityId}/recommendation?key=${key}`,
    );
    xhr.setRequestHeader('Authorization', getToken());
    xhr.send();
  });
}

function getRecommemdMidWordExport(key: string = '', activityId: number) {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function ({ target }) {
      if ((target as XMLHttpRequest).status !== 200) {
        return rej(target);
      }

      const blob = new Blob([xhr.response], {
        type: 'application/msword',
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      let fileName = '';
      link.href = window.URL.createObjectURL(blob);
      const contentDisposition = xhr.getResponseHeader('content-disposition');
      if (contentDisposition) {
        const descArrays = contentDisposition.split('=');
        fileName = decodeURI(descArrays[1]);
      }
      link.download = fileName;
      link.click();
      res(target);
    };
    xhr.responseType = 'blob';
    xhr.open(
      'GET',
      `${BASE_URL}/activity/item/institution/export/${activityId}/recommendation?key=${key}&orderByRecommendField="midTermSummaryRecommendNum"`,
    );
    xhr.setRequestHeader('Authorization', getToken());
    xhr.send();
  });
}
// 项目管理
// 推荐列表
function getRecommendList(params: Object, data: Object) {
  const sort = 'productName';
  return http.post(`/activity/item/platform/search`, data, { params: params });
}
// 修改推荐排名
function putRank(id: number, rank: number) {
  return http.put(`/activity/item/recommendation/rank/${id}?rank=${rank}`);
}
// 修改推荐排名
function putMidRank(id: number, rank: number) {
  return http.put(`/activity/item/mid-term-summary/recommendation/rank/${id}?rank=${rank}`);
}
// 获取附件地址
function getAttachmentURL(id: string) {
  return http.get(`/file/attachment?id=${id}`);
}

// 地方政府账号管理区域
function getUserManagedCity() {
  return http.get(`/user/managed/city`);
}

// 地方政府提交推荐列表
function putActivityCommit(activityId: number) {
  return http.put(`/activity/${activityId}/commit`);
}
// 获取企业认证信息
function getCertification() {
  return http.get(`/certification/me`);
}
// 提交企业认证信息
function certificate(params: Object) {
  return http.post(`/certification`, params);
}

function getProDirection(activityId: string) {
  return http.get(`/product/types/${activityId}`);
}

function getDraftList(data: any) {
  return http.get(`/activity/item/draft`, { params: data });
}

function postDraft(data: any) {
  return http.post(`/activity/item/draft`, data)
}

function getDraftDetail(draftId: any) {
  return http.get(`/activity/item/draft/${draftId}`)
}

function deleteDraft(draftId: any) {
  return http.delete(`/activity/item/draft/${draftId}`);
}

function getProductStatus() {
  return http.get(`/activity/item/status`);
}
function getInstitutionList(data: any) {
  return http.get(`/institution/1/recommend/file`, { params: data });
}
function attachmentPdf(data: any) {
  return http.get(`/file/attachment`, { params: data });
}
// 删除上传文件
function deleteInstitution(data: string) {
  return http.delete(`/institution/1/recommend/file`,{data:data},);
}
// 上传文件
function submitInstitution(data: string, activityId: number) {
  return http.post(`/institution/1/recommend/file/confirm?activityId=${activityId}`, data,{
    headers: {
      'Content-Type': 'text/plain',
    },
   
  });
}

export function getSliderCaptcha() {
  return http.get("/authorize");
}

export function checkSliderCaptcha(params: object) {
  return http.get("/authorize/check", {params});
}
export function getPublicNeed(params: object) {
  return http.get("/authorize/need", {params});
}
//提交中期总结
export function submitMiddleSummary(itemId:any ,params:object) {
  return http.post(`/activity/item/${itemId}/mid/term/summary`, params);
}

//提交中期总结
export function cacheSubmitMiddleSummary(itemId:any ,params:object) {
  return http.post(`/activity/item/${itemId}/cache/mid/term/summary`, params);
}
//中期总结回显
export function getSubmitMiddleSummary(itemId:any) {
  return http.get(`/activity/item/${itemId}/mid/term/summary`);
}

//中期总结回显
export function changeMidTermUpload(data:object) {
  return http.put(`/activity/item/change/mid/term/upload`, data);
}

export {

  getProductStatus,
  getActivityList,
  getAppliedList,
  getAddressData,
  getOrganizationType,
  getProductType,
  getInstitution,
  uploadAppenderFile,
  postForm,
  getFormDetail,
  getResetPasswordSms,
  getRegisterSms,
  postRegister,
  postResetPassword,
  getProfile,
  postProfile,
  uploadFile,
  postResetPasswordFromOldPwd,
  postActivityLocationList,
  downloadFile,
  getRecommendList,
  putRank,
  putMidRank,
  getActivityRecommend,
  getActivityMidRecommend,
  getFileExport,
  removeActivityRecommend,
  removeActivityMidRecommend,
  getAttachmentURL,
  putActivityAccepted,
  deleteActivityAccepted,
  getUserManagedCity,
  putActivityCommit,
  getRecommemdWordExport,
  getRecommemdMidWordExport,
  getUserIcon,
  getActivityType,
  getCertification,
  certificate,
  getActivityUpdateRecommend,
  getActivityUpdateMidRecommend,
  getActivityUpdateMidRecommendation,
  getActivityCategory,
  postExpertCommitment,
  getExpertInfo,
  getExpertProjectList,
  putExpertIcon,
  getExpertProjectDetail,
  getExpertScore,
  putExpertScore,
  getProDirection,
  getDraftList,
  postDraft,
  getDraftDetail,
  deleteDraft,
  getInstitutionList,
  deleteInstitution,
  submitInstitution,
  getPdfFileExport,
  attachmentPdf,
};
